@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap);
@font-face {
  font-family: "Unna";
  src: url(/static/media/Unna-BoldItalic.dcfdf038.woff2) format("woff2"),
    url(/static/media/Unna-BoldItalic.b22a0499.woff) format("woff");
  font-weight: bold;
  font-style: normal; /* We are using an italic font even for normal style headings. DONT CHANGE */
}

@font-face {
  font-family: "Ubuntu";
  src: url(/static/media/Ubuntu-Regular.d3ad5a43.woff2) format("woff2"),
    url(/static/media/Ubuntu-Regular.7e090703.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuItalic";
  src: url(/static/media/Ubuntu-Italic.3c21c3ab.woff2) format("woff2"),
    url(/static/media/Ubuntu-Italic.d5ac033c.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "UbuntuMedium";
  src: url(/static/media/Ubuntu-Medium.77a5f789.woff2) format("woff2"),
    url(/static/media/Ubuntu-Medium.974aa623.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "UbuntuMediumItalic";
  src: url(/static/media/Ubuntu-MediumItalic.be96c57a.woff2) format("woff2"),
    url(/static/media/Ubuntu-MediumItalic.65c3d77d.woff) format("woff");
  font-weight: 500;
  font-style: italic;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  text-decoration: none;
}

/* 
table {
  border-collapse: collapse;
}

td {
  border: 1px solid lightgray;
  padding: 0.25rem;
} */

* {
  box-sizing: border-box;
}

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #3e3e4e;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.cc-input input,
.StripeElement {
  display: block;
  margin: 10px 0 0 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  outline: 0;
  background: white;
  border-radius: 0;
  border-bottom: 2px solid #29283a;
}

.cc-input input::-webkit-input-placeholder {
  color: #aab7c4;
}

.cc-input input:-ms-input-placeholder {
  color: #aab7c4;
}

.cc-input input::placeholder {
  color: #aab7c4;
}

.cc-input input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  border-bottom: 2px solid #30c97f;
  transition: all 150ms ease;
}

.cc-input > label {
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  font-size: 12px;
}
