@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap");
* {
  box-sizing: border-box;
}

.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #3e3e4e;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.cc-input input,
.StripeElement {
  display: block;
  margin: 10px 0 0 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  outline: 0;
  background: white;
  border-radius: 0;
  border-bottom: 2px solid #29283a;
}

.cc-input input::placeholder {
  color: #aab7c4;
}

.cc-input input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  border-bottom: 2px solid #30c97f;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.cc-input > label {
  font-family: "Ubuntu", Helvetica Neue, Helvetica, Arial, sans-serif;
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  font-size: 12px;
}